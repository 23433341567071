import React from "react"

import EmptyLayout from "@components/empty-layout"
import SEO from "@components/seo"

const VIPiPhonePage = () => (
  <EmptyLayout bodyClassName='coachSignup'>
    <SEO 
      title="Get Notified for Harper Coach"
      description="Harper Coach is 1:1 remote training with a real dog trainer, powered by an awesome app, dedicated to helping you and your pup reach your goals." 
    />

    <section className="stripe" id="coachForm">
      <div className="container">
        <a href="/coach/" className="backArrowButton">Go back</a>
        <h2 className="processSubhead">Program full</h2>
        <h1 className="sectionTitle">Ok you’re on the list</h1>
        <p className="prose">We&rsquo;ll send you an email once we&rsquo;re able to get you and your pup into the program.</p>      
      </div>
    </section>
  </EmptyLayout>
);

export default VIPiPhonePage
